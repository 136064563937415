import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "q-header-notifications__content" }
const _hoisted_2 = { class: "q-header-notifications__content-item" }
const _hoisted_3 = { class: "q-header-notifications__content-item-icon" }
const _hoisted_4 = { class: "q-header-notifications__content-item-content" }
const _hoisted_5 = { class: "q-header-notifications__content-item-content-topic" }
const _hoisted_6 = { class: "q-header-notifications__content-item-content-description" }
const _hoisted_7 = { class: "q-header-notifications__content-item" }
const _hoisted_8 = { class: "q-header-notifications__content-item-icon" }
const _hoisted_9 = { class: "q-header-notifications__content-item-content" }
const _hoisted_10 = { class: "q-header-notifications__content-item-content-topic" }
const _hoisted_11 = { class: "q-header-notifications__content-item-content-description" }
const _hoisted_12 = { class: "q-header-notifications__button" }

import { computed } from 'vue'
import { FileDoneOutlined, LogoutOutlined, MailOutlined } from '@ant-design/icons-vue'
import {
  Empty as AEmpty,
  Menu as AMenu,
  MenuItem as AMenuItem,
} from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useLogout from '@/сomposables/logout'
import type { IStore } from '@/store/interfaces'
import { DATE_FORMAT_WITH_HOUR_AND_MINUTE } from '@/constants/date'
import type { ITask } from '@/interfaces'


export default /*@__PURE__*/_defineComponent({
  __name: 'QHeaderNotifications',
  setup(__props) {

const route = useRoute()
const router = useRouter()
const exit = useLogout()
const store: IStore = useStore()

const task = computed<ITask | null>(() => store.getters.getTask)

function goTo(id?: number) {
  if (id) {
    router.push({ path: `/resident/${route.params?.userId}/chats/${id}` })
  }
  else {
    router.push({ path: `/resident/${route.params?.userId}/appeals/recalculation` })
  }
}

function duration(date: string) {
  dayjs.extend(relativeTime)

  const currentDate = dayjs()
  const inputDate = dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE)
  const hour = currentDate.diff(inputDate, 'hour')

  let res = null

  if (hour < 12) {
    res = dayjs().to(dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE))
  }
  else {
    res = `c ${dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE).format(DATE_FORMAT_WITH_HOUR_AND_MINUTE)}`
  }
  return res
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AMenu), { class: "q-header-notifications" }, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "q-header-notifications__main" }, " Задачи ", -1)),
      _createElementVNode("div", _hoisted_1, [
        (!task.value)
          ? (_openBlock(), _createBlock(_unref(AEmpty), {
              key: 0,
              class: "q-header-notifications__content-empty"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(task.value.chats, (chat) => {
                return (_openBlock(), _createBlock(_unref(AMenuItem), {
                  key: chat.id,
                  onClick: ($event: any) => (goTo(chat.id))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(MailOutlined))
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(chat.content), 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(duration(chat.created)) + " в обращениях ", 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128)),
              (task.value.changeObject?.count)
                ? (_openBlock(), _createBlock(_unref(AMenuItem), {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = () => goTo())
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_unref(FileDoneOutlined))
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, " Перерасчеты на доработке: " + _toDisplayString(task.value.changeObject.count || 0), 1),
                          _createElementVNode("span", _hoisted_11, _toDisplayString(task.value.changeObject.created), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 64))
      ]),
      _createVNode(_unref(AMenuItem), { onClick: _unref(exit) }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_unref(LogoutOutlined)),
            _cache[1] || (_cache[1] = _createTextVNode(" Выйти из аккаунта "))
          ])
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}
}

})