import { AltchaAPIInstance, DefaultAPIInstance, LoginAPIInstance } from '@/api'

const AuthAPI = {
  login(user) {
    const url = 'oauth/token'
    const data = new URLSearchParams()
    data.append('grant_type', 'password')
    data.append('username', user.login)
    data.append('password', user.password)
    data.append('altcha', user.altcha)
    return LoginAPIInstance.post(url, data)
  },

  refreshToken() {
    const url = 'oauth/token'
    const data = new URLSearchParams()
    data.append('grant_type', 'refresh_token')
    data.append('refresh_token', localStorage.getItem('refresh_token'))
    return LoginAPIInstance.post(url, data)
  },

  getUser() {
    const url = 'main/user'
    return DefaultAPIInstance.get(url)
  },

  getAltchaChallenge() {
    const url = 'client/freeAccess/altcha/getChallenge'
    return AltchaAPIInstance.get(url)
  },
}

export default AuthAPI
