import type { Module } from 'vuex'
import { message } from 'ant-design-vue'
import type { IRootStore } from '../interfaces'
import type { INotificationState } from './interfaces'
import CommunicationService from '@/services/main/CommunicationService'

const notification: Module<INotificationState, IRootStore> = {
  state: {
    countNotifications: 0,
    task: null,
  },

  getters: {
    countNotifications: state => state.countNotifications,
    getTask: state => state.task,
  },

  actions: {
    async getNotifications({ commit }) {
      try {
        const { data } = await CommunicationService.getUnreadMessages()
        commit('setCount', data)
      }
      catch (err: any) {
        message.error(err.response.data?.details)
      }
    },

    updateTask({ commit }, data) {
      commit('setTask', data)
      const tasks = Number(data?.changeObject?.count) || 0
      const chats = Number(data?.chats?.length) || 0
      const notificationCount = tasks + chats
      commit('setCount', notificationCount)
    },
  },

  mutations: {
    setCount: (state, data) => {
      state.countNotifications = data
    },

    setTask: (state, data) => {
      state.task = data
    },
  },
}

export default notification
