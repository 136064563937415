<template>
  <AMenu class="q-header-notifications">
    <div class="q-header-notifications__main">
      Задачи
    </div>
    <div class="q-header-notifications__content">
      <AEmpty
        v-if="!task"
        class="q-header-notifications__content-empty"
      />
      <template v-else>
        <AMenuItem
          v-for="chat in task.chats"
          :key="chat.id"
          @click="goTo(chat.id)"
        >
          <div class="q-header-notifications__content-item">
            <div class="q-header-notifications__content-item-icon">
              <MailOutlined />
            </div>
            <div class="q-header-notifications__content-item-content">
              <span class="q-header-notifications__content-item-content-topic">
                {{ chat.content }}
              </span>
              <span class="q-header-notifications__content-item-content-description">
                {{ duration(chat.created) }} в обращениях
              </span>
            </div>
          </div>
        </AMenuItem>
        <AMenuItem v-if="task.changeObject?.count" @click="() => goTo()">
          <div class="q-header-notifications__content-item">
            <div class="q-header-notifications__content-item-icon">
              <FileDoneOutlined />
            </div>
            <div class="q-header-notifications__content-item-content">
              <span class="q-header-notifications__content-item-content-topic">
                Перерасчеты на доработке: {{ task.changeObject.count || 0 }}
              </span>
              <span class="q-header-notifications__content-item-content-description">
                {{ task.changeObject.created }}
              </span>
            </div>
          </div>
        </AMenuItem>
      </template>
    </div>
    <AMenuItem @click="exit">
      <div class="q-header-notifications__button">
        <LogoutOutlined />
        Выйти из аккаунта
      </div>
    </AMenuItem>
  </AMenu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { FileDoneOutlined, LogoutOutlined, MailOutlined } from '@ant-design/icons-vue'
import {
  Empty as AEmpty,
  Menu as AMenu,
  MenuItem as AMenuItem,
} from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useLogout from '@/сomposables/logout'
import type { IStore } from '@/store/interfaces'
import { DATE_FORMAT_WITH_HOUR_AND_MINUTE } from '@/constants/date'
import type { ITask } from '@/interfaces'

const route = useRoute()
const router = useRouter()
const exit = useLogout()
const store: IStore = useStore()

const task = computed<ITask | null>(() => store.getters.getTask)

function goTo(id?: number) {
  if (id) {
    router.push({ path: `/resident/${route.params?.userId}/chats/${id}` })
  }
  else {
    router.push({ path: `/resident/${route.params?.userId}/appeals/recalculation` })
  }
}

function duration(date: string) {
  dayjs.extend(relativeTime)

  const currentDate = dayjs()
  const inputDate = dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE)
  const hour = currentDate.diff(inputDate, 'hour')

  let res = null

  if (hour < 12) {
    res = dayjs().to(dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE))
  }
  else {
    res = `c ${dayjs(date, DATE_FORMAT_WITH_HOUR_AND_MINUTE).format(DATE_FORMAT_WITH_HOUR_AND_MINUTE)}`
  }
  return res
}
</script>

<style lang="sass" scoped>
@import './QHeaderNotifications'
</style>
