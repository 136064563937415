export const DATE_FORMAT = 'DD.MM.YYYY'

export const MARKETPLACE_DATE_FORMAT = 'YYYY-MM-DD'

export const DATE_FORMAT_TIME = 'DD.MM.YYYYTHH:mm'

export const DATE_FORMAT_WITH_HOUR_AND_MINUTE = 'DD.MM.YYYY hh:mm'

export const DATE_ENDLESS = '31.12.9999'

export const DATE_FIND_PAYMENT_FROM = '01.01.2019'
