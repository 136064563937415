import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { useStore } from 'vuex'
import type { IStore } from '@/store/interfaces'

const store: IStore = useStore()

function useWebSocket() {
  let client: Client

  const createWebSocket = () => {
    try {
      return new SockJS(process.env.VUE_APP_API_WS)
    }
    catch (error) {
      console.error('Ошибка при создании WebSocket:', error)
    }
  }

  const subscribeToRoom = async (userId: string, authorization: string) => {
    try {
      client.subscribe(`/user/${userId}/task`,
        (message: any) => store?.dispatch('updateTask', JSON.parse(message.body)),
        { authorization })
    }
    catch {
      console.error(`Не удалось подписаться на комнату, ${userId}`)
    }
  }

  const connect = (userId: string, token: string) => {
    client = new Client({
      webSocketFactory: createWebSocket,
      connectHeaders: {
        authorization: `${token}`,
      },
      reconnectDelay: 5000,
      beforeConnect: () => {
        return new Promise((resolve) => {
          resolve()
        })
      },
      onConnect: () => {
        subscribeToRoom(userId, token)
      },
      onStompError: (frame) => {
        const error = `STOMP error: ${frame}`
        console.error(error)
      },
      onDisconnect: (frame) => {
        console.log('onDisconnect', frame)
      },
      onWebSocketError: (frame) => {
        console.error('onWebSocketError', frame)
      },
      onWebSocketClose: (frame) => {
        console.log('onWebSocketClose', frame)
      },
    })

    client?.activate()
  }

  const disconnect = () => {
    store?.dispatch('updateTask', null)
    client?.deactivate()
    console.log('Disconnected')
  }

  return {
    connect,
    disconnect,
  }
}

export default useWebSocket
