import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ruRU from 'ant-design-vue/es/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {
  ConfigProvider as AConfigProvider,
  StyleProvider as AStyleProvider,
} from 'ant-design-vue'
import type { ThemeConfig } from 'ant-design-vue/lib/config-provider/context'
import { AGREEMENT_COOKIE } from './constants/cookie'
import QCookieAgreement from '@/components/cookie-agreement/QCookieAgreement.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

dayjs.locale('ru')
const route = useRoute()
const locale = ref(ruRU)

const layout = computed(() => route.meta.layout)
const agreement = ref(true)

const theme: ThemeConfig = {
  components: {
    Modal: {
      padding: 0,
      paddingContentHorizontalLG: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
    },
    Tag: {
      colorTextLightSolid: 'currentColor',
    },
  },
}

onMounted(() => {
  setTimeout(() => {
    agreement.value = document.cookie.includes(AGREEMENT_COOKIE)
  }, 3000)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AConfigProvider), {
    locale: locale.value,
    theme: theme
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(AStyleProvider), { "hash-priority": "high" }, {
        default: _withCtx(() => [
          (layout.value)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value), { key: 0 }))
            : _createCommentVNode("", true),
          (!agreement.value)
            ? (_openBlock(), _createBlock(QCookieAgreement, {
                key: 1,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (agreement.value = true))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})